
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import store from "@/store";
import { config } from "@/core/helpers/config";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import ClientClaimsTable from "@/components/individual/ClientClaimsTable.vue";

export default defineComponent({
  name: "cooperative-society-details",
  components: { GoBackButton, ClientClaimsTable },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageBreadcrumbs("Details", ["Cooperative Society"]);
    });

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      config,
      setActiveTab,
    };
  },
  data() {
    return {
      publicId: this.$route.params.publicId,
      cooperative: {},
      bankDetails: {},
      bank: {},
      gender: {},
      cooperativeAdministrator: {},
      banks: [],
      rates: [],
      members: [],
      rateRoute: "",
      updateRoute: "",
      detailsRoute: "",
      imageRoute: variables.AVATAR_ROUTE,
      moiImage: "",
      policies: [],
      claims: [],
      role: "",
    };
  },
  created() {
    // Redirect to page after successfully login
    const user = store.getters.currentUser;
    this.role =
      user.role == variables.SUPER_ADMIN_USER_ROLE ? "super-admin" : "broker";

    // The the default routes based on the authenticated user role
    this.rateRoute =
      user.role == variables.SUPER_ADMIN_USER_ROLE
        ? variables.SUPERADMIN_COOPERATIVE_RATE_ROUTE
        : variables.BROKER_COOPERATIVE_RATE_ROUTE;

    this.detailsRoute =
      user.role == variables.SUPER_ADMIN_USER_ROLE
        ? variables.SUPERADMIN_COOPERATIVE_DETAILS_ROUTE
        : variables.BROKER_COOPERATIVE_DETAILS_ROUTE;

    // Get the cooperative information
    this.getCooperativeInformation();

    this.displayDatatable();

    //Set page title
    document.title =
      "Cooperative Society Details | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getCooperativeInformation() {
      // Get the private motor rate
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.detailsRoute}/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to form fields
            this.cooperative = data.data;
            this.bankDetails = data.data.bankDetails;
            this.bank = data.data.bankDetails.bank;
            this.cooperativeAdministrator = data.data.cooperativeAdministrator;
            this.gender = data.data.cooperativeAdministrator.gender;
            this.rates = data.data.rates;
            this.members = data.data.members;
            this.policies = data.data.policies;
            this.claims = data.data.claims;

            if(data.data.cooperativeAdministrator.identificationImage != ''){
              this.getMoiImage(
              data.data.cooperativeAdministrator.identificationImage
            );
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
        });
      }, 3000);
    },
    getMoiImage(value) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.MEANS_OF_ID_IMAGE_ROUTE}${value}`)
          .then(({ data }) => {
            //Assign data to form fields
            this.moiImage = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
